
import { defineComponent, onMounted, ref } from "vue";
document.title = "珍盛珠宝";
export default defineComponent({
  name: "HomeView",
  setup() {
    const activeName = ref("first");
    const act = ref(false);
    const actR = ref(false);
    const actB = ref(false);
    const handleClick = (val: any) => {
      const name = val.props.name;
      if (name == "first") {
        document.body.scrollTop = 650;
        document.documentElement.scrollTop = 650;
      } else if (name == "second") {
        document.body.scrollTop = 1300;
        document.documentElement.scrollTop = 1300;
      } else {
        document.documentElement.scrollTop = 1600;
      }
      activeName.value = name;
    };
    onMounted(() => {
      // 监听滚动条位置
      window.addEventListener("scroll", scrollTop, true);
    });
    // 实时滚动条高度
    const scrollTop = () => {
      let scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 280) {
        // actR.value = true;
        act.value = true;
      }
      if (scroll >= 900) {
        actR.value = true;
      }
      if (scroll >= 1240) {
        actB.value = true;
      }
      activeName.value =
        scroll >= 1200 ? (scroll >= 1550 ? "thirst" : "second") : "first";
    };
    return {
      activeName,
      handleClick,
      actR,
      act,
      actB,
    };
  },
});
